<template>
  <div class="result-container">
    <p class="state">사용가능상태</p>
    <div class="bs-flex">
      <div class="tab-title" :class="{active :tabIndex === 0}" @click="tabIndex=0">
        회원정보
      </div>
      <div class="tab-title" :class="{active :tabIndex === 1}" @click="tabIndex=1">
        담당 환자
      </div>
    </div>
    <hr/>
    <div class="user-info-tab" v-if="tabIndex === 0">
      <div class="info-flex">
        <div class="info-left">
          <div class="bs-flex">
            <h5 class="info-title">아이디</h5>
            <input type="text" v-model="selData.email" disabled class="custom-input-box w247"/>
            <h5 class="info-title ml46">소속병원 이름</h5>
            <input type="text" v-model="selData.hospitalInfo.name" disabled class="custom-input-box w247"/>
          </div>
          <div class="bs-flex">
            <h5 class="info-title">휴대폰번호</h5>
            <input type="text" v-model="selData.mobile" disabled class="custom-input-box w247"/>
            <h5 class="info-title ml46">소속병원 형태</h5>
            <input type="text" v-model="selData.hospitalInfo.type" disabled class="custom-input-box w247"/>
          </div>
          <div class="bs-flex">
            <h5 class="info-title">이름</h5>
            <input type="text" v-model="selData.name" disabled class="custom-input-box w247"/>
            <h5 class="info-title ml46">소속병원 위치</h5>
            <input type="text" v-model="selData.hospitalInfo.location" disabled class="custom-input-box w247"/>
          </div>
          <div class="bs-flex">
            <h5 class="info-title">생년월일</h5>
            <input type="text" v-model="selData.birth" disabled class="custom-input-box w247"/>
            <h5 class="info-title ml46">직업</h5>
            <input type="text" v-model="selData.hospitalInfo.job" disabled class="custom-input-box w247"/>
          </div>
          <div class="bs-flex">
            <h5 class="info-title">성별</h5>
            <mdb-form-inline class="w247">
              <mdb-input type="radio" id="gender-1" name="groupOfMaterialRadios2" disabled radioValue="남성" v-model="selData.gender" label="남성"/>
              <mdb-input type="radio" id="gender-2" name="groupOfMaterialRadios2" disabled radioValue="여성" v-model="selData.gender" label="여성"/>
            </mdb-form-inline>
            <h5 class="info-title ml46">근무년수</h5>
            <input type="text" v-model="selData.hospitalInfo.workingYear" disabled class="custom-input-box w247"/>
          </div>
        </div>
      </div>
      <hr class="second-hr"/>
      <div class="bs-flex">
        <button class="btn-use" v-if="selData.state!=='사용가능'" @click="stateChange('사용가능')">사용 가능 변환</button>
        <button class="btn-limit" v-if="selData.state!=='접근제한' && selData.state!=='제명'" @click="stateChange('접근제한')">접근 제한</button>
        <button class="btn-fire" v-if="selData.state!=='제명'" @click="stateChange('제명')">제명</button>
      </div>
    </div>
    <MyPatient v-if="tabIndex === 1" :dataPatientsList="dataPatientsList"/>
  </div>
</template>

<script>
import {mdbFormInline, mdbInput} from "mdbvue";
import MyPatient from "@/views/master/therapist/components/MyPatient";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "TherapistInfo",
  components: {
    MyPatient,
    mdbInput,
    mdbFormInline,
  },
  props: {
    selData: Object,
    dataPatientsList: Object,
  },
  data() {
    return {
      tabIndex: 0,
      fbCollection: 'therapists',
    }
  },
  methods: {
    getBirthYear(value) {
      const self = this;
      self.birthYear = value
    },
    getBirthMonth(value) {
      const self = this;
      self.birthMonth = value
    },
    getBirthDay(value) {
      const self = this;
      self.birthday = value
    },
    stateChange(state) {
      const self = this;
      const docRef = firebase.firestore().collection(self.fbCollection);

      docRef
          .doc(self.selData.docId)
          .update({state: state}).then(async () => {
        alert('수정되었습니다.');
        self.$emit('refresh', 0, state);

      });
    },
  }
}
</script>

<style scoped>
.result-container {
  margin-top: 15px;
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 18px;
}

.state {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #0069CA;
  margin: 0 0 9px 0;
}

.tab-title {
  width: 124px;
  border-bottom: 2px solid #E1E3E6;
  padding-bottom: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #A0B0C6;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.active {
  width: 124px;
  border-bottom: 2px solid #0A38F5;
  padding-bottom: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

hr {
  position: relative;
  z-index: 0;
  padding: 0;
  border-top: 2px solid #E1E3E6;
  margin: -2px 0 43px;
}

.second-hr {
  position: relative;
  z-index: 0;
  padding: 0;
  border-top: 2px solid #E1E3E6;
  margin: 5px 0 27px;
}

.bs-flex {
  display: flex;
  flex-direction: row;
}

.info-flex {
  display: flex;
  flex-direction: row;
}

.info-flex .bs-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 124px;
}

.w247 {
  width: 247px;
  font-size: 16px;
}

.w78 {
  width: 78px;
  font-size: 16px;
}

.w78:nth-child(3) {
  width: 78px;
  margin: 0 6px;
}

.ml46 {
  margin-left: 46px;
  width: 145px;
}

.btn-use {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.btn-limit {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #E14F4E;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.btn-fire {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #222539;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
  margin-bottom: 14px;
}


</style>
