export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '활동상태',
        field: 'state2',
        sort: true
    },
    {
        label: '이름',
        field: 'name',
        sort: true
    },
    {
        label: '아이디(이메일)',
        field: 'email',
        sort: false
    },
    {
        label: '소속병원',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: '최근 접속 일자',
        field: 'lastLog',
        sort: false
    },
    {
        label: '성별',
        field: 'gender',
        sort: false
    },
    {
        label: '연락처',
        field: 'mobile',
        sort: false
    },
    {
        label: '생년월일',
        field: 'birth',
        sort: false
    },
    {
        label: '등록환자수',
        field: 'patientsCount',
        sort: false
    },
];
