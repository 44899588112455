<template>
  <div class="table-container">
    <mdb-datatable-2
        v-model="dataPatientsList"
        class="text-center"
        borderless
        hover
        noFoundMessage="등록된 환자가 없습니다."/>
  </div>
</template>

<script>
import {mdbDatatable2} from "mdbvue";

export default {
  name: "MyPatient",
  components: {
    mdbDatatable2,
  },
  props: {
    dataPatientsList: Object
  },
}
</script>

<style scoped>
.table-container {
  width: 100%;
  max-height: 496px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 40px;
}
</style>
