export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '환자번호',
        field: 'patientNo',
        sort: true
    },
    {
        label: '환자이름',
        field: 'name',
        sort: true
    },
    {
        label: '진단명',
        field: 'diagnosis',
        sort: false
    },
    {
        label: '최근 진단 일자',
        field: 'lastDate',
        sort: false
    }
];
